window.global ||= window;
// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import ReactDOM from "react-dom";

import Entry from "modules/0_web/1_entry.jsx";

// scss imports
import "~/stylesheets/main_styles/base.scss";
import "font-awesome/css/font-awesome.min.css";
import "react-chat-elements/dist/main.css";
import "../scss/custom.scss";

// Extending lodash
const isNotEmpty = (value) => {
  if (_.isArray(value)) {
    if (_.isEmpty(value)) {
      return false;
    } else {
      return !_.some(value, (v) => _.isEmpty(v));
    }
  } else {
    return !_.isEmpty(value);
  }
};

const isPresent = (value) => {
  return !_.isEmpty(value);
};

const sortByKeys = (object, comparator, direction = "ASC") => {
  var sortedKeys = _.orderBy(
    _.keys(object),
    function (key) {
      return comparator ? comparator(object[key], key) : key;
    },
    direction
  );

  const mappedKeysValues = _.map(sortedKeys, (key) => [` ${key}`, object[key]]);
  return _.fromPairs(mappedKeysValues);
};

_.mixin({ isNotEmpty: isNotEmpty, sortByKeys: sortByKeys, isPresent: isPresent });

// Extending lodash

// Format Dates
window.fd = (date) => {
  return date ? moment(date).format("DD/MM/YYYY") : "";
};
// Format Dates

// CSRF
axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector("meta[name='csrf-token']").getAttribute("content");
// CSRF

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Entry />, document.getElementById("react_container"));
});
